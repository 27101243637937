.plugin {
  &__price {
    padding-top: $input-btn-padding-y;
  }

  .badge {
    width: auto;
    max-width: 200px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
