.tour {
  .modal {
    &-content {
      border: 0;
    }

    &-footer, &-header, &-body {
      border: 0;
    }
  }
}
