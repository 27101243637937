.gallery {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;

    div.file-upload {
        grid-column: auto;
        grid-row: auto;
    }

    img, .gallery__add {
        aspect-ratio: 1/1;
        object-fit: cover;
        position: relative;
    }

    &__item {
        position: relative;

        &:first-child {
            grid-column: 1/span 2;
            grid-row: 1/span 2;
        }

        .btn-close {
            display: none;
        }

        &:hover {
            &::after {
                content: ' ';
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background-color: rgba(0, 0, 0, 0.5);
                z-index: 1;
                border-radius: var(--bs-border-radius);
            }

            .btn-close {
                display: block;
                position: absolute;
                right: 0;
                top: 0;
                z-index: 1000;
            }
        }
    }
}