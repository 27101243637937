.about {
  background-image: url('../../public/images/backgrounds/about.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  bottom: 0;
  left: 0;

  .badge {
   background: #EBF2FC1A;
  }
}
