.select--no-bg {
    background: transparent;
    color: white;
    border: none;
    background-image: url('../../public/images/icons/arrow.svg');
    background-size: 1em auto;
    background-repeat: no-repeat;
    background-position: calc(100% - 8px) center;
    appearance: none;
    padding-right: 30px;

    option {
        color: white;
        background-color: $cad-dark-blue;
    }
}

/** This is for the third party Select component @see https://react-select.com/styles **/

.app-select {
    div &__control {
        @extend .form-select;
        padding: calc($input-btn-padding-y / 2) $input-btn-padding-x !important;
        min-height: calc($input-btn-padding-y * 2 + 1.5em);
        display: flex;
        align-items: center;
        overflow-y: auto;
    }

    /** Those are addtional buttons like reset all entries and so on. If we need this just enable and style differently **/
    &__indicators {
        display: none !important;
    }

    &__value-container {
        padding: 0 !important;
    }

    &__input-container {
        padding: 0 !important;
        margin: 0 !important;

        &::placeholder {
            color: red;
            opacity: 1;
        }

        &::-ms-input-placeholder {
            color: red;
        }
    }

    div &__multi-value {
        @extend .badge;
        background: $cad-primary-light !important;
        padding: $input-btn-padding-y / 2 $input-btn-padding-x / 2;
        display: flex;
        align-items: center;
        justify-content: between;

        &__label, &__remove {
            color: white !important;
        }

        &__remove {
            &:hover {
                background: rgba(0, 0, 0, 0.1) !important;
            }
        }
    }
}
