.appendix {
  position: relative;
}

.appendix--topbottom {
  &::before {
    content: '';
    height: 12px;
    width: 40%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0px 4px 0px 0px;
    z-index: 10;
  }

  &::after {
    content: '';
    height: 8px;
    width: 20%;
    position: absolute;
    bottom: 0;
    right: 0;
    border-radius: 0px 0px 0px 4px;
    z-index: 10;
  }
}

.appendix--topleft {
  &::before {
    content: '';
    height: 4px;
    width: 40%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0px 4px 0px 0px;
    z-index: 10;
  }
}

.appendix--topright {
  &::before {
    content: '';
    height: 6px;
    width: 40%;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 0px 0px 0px 4px;
    z-index: 10;
  }
}

.appendix--bottomleft {
  &::after {
    content: '';
    height: 6px;
    width: 40%;
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 0px 4px 0px 0px;
    z-index: 10;
  }
}

.appendix--bottomright {
  &::after {
    content: '';
    height: 4px;
    width: 40%;
    position: absolute;
    bottom: 0;
    right: 0;
    border-radius: 0px 0px 0px 4px;
    z-index: 10;
  }
}

.appendix--big {
  &::before {
    border-radius: 0px 0px 0px 12px !important;
    height: 12px !important;
  }

  &::after {
    border-radius: 0px 0px 0px 12px !important;
    height: 12px !important;
  }
}

.appendix--medium {
  &::before {
    border-radius: 0px 0px 0px 8px !important;
    height: 8px !important;
  }

  &::after {
    border-radius: 0px 0px 0px 8px !important;
    height: 8px !important;
  }

}

.appendix--small {
  &::before {
    border-radius: 0px 0px 0px 4px !important;
    height: 4px !important;
  }

  &::after {
    border-radius: 0px 0px 0px 4px !important;
    height: 4px !important;
  }
}

.appendix--primary {
  &::before {
    background: $cad-primary;
  }

  &::after {
    background: $cad-primary;
  }
}

.appendix--dark {
  &::before {
    background: $cad-dark-blue;
  }

  &::after {
    background: $cad-dark-blue;
  }
}

.appendix--turquoise {
  &::before {
    background: $cad-turquoise;
  }

  &::after {
    background: $cad-turquoise;
  }
}
