// Bootstrap | Frameworks
$theme-colors: (
        "primary": $cad-primary,
        "dark": $cad-typo-dark,
        "secondary": $cad-typo-lighter,
        "muted": $cad-muted,
        "light": $cad-lightgray,
        "info": $cad-warning,
        "success": $cad-success,
        "danger": $cad-error,
        "warning": $cad-warning,
        "midnight": $cad-midnight,
        "turquoise": $cad-turquoise,
        "primary-light": $cad-primary-light,
        "light-blue": $cad-inputbg,
        "dark-blue": $cad-dark-blue
);

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        "xxl": 1600px
);

$body-color: $cad-typo-default;
$link-color: $cad-primary;

$font-family-base: 'Rubik', system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$font-weight-semibold: 500;

$font-size-base: 1rem;
$font-size-sm: $font-size-base * .866;
$font-size-xs: $font-size-base * .8;

$h1-font-size: $font-size-base * 3;
$h2-font-size: $font-size-base * 2.5;
$h3-font-size: $font-size-base * 1.3;
$h4-font-size: $font-size-base * 1.1;
$h5-font-size: $font-size-base * 1;

$font-sizes: (
        1: $h1-font-size,
        2: $h2-font-size,
        3: $h3-font-size,
        4: $h4-font-size,
        5: $font-size-sm,
        6: $font-size-xs
);

// General

$spacer: 1rem;

$border-color: $cad-stroke;

// Inputs x Button

$input-padding-y: .75rem;

$input-btn-padding-y: calc(.75rem - 2px);
$input-btn-padding-x: 1rem;

$input-padding-y-sm: .5rem;

$input-btn-padding-y-sm: .5rem;
$input-btn-padding-x-sm: .85rem;

$input-padding-y-lg: 1rem;

$input-btn-padding-y-lg: 1rem;
$input-btn-padding-x-lg: 1.35rem;

$input-padding-x-lg: 1.5rem;
$input-btn-padding-x-lg: 1.5rem;

$input-disabled-bg: #FCFCFA;

$input-border-color: $cad-stroke;
$input-focus-border-color: $cad-primary;
$input-focus-box-shadow: 0 0 0 0.21rem rgba($cad-primary, 0.2);
$input-btn-focus-box-shadow: 0 0 0 0.15rem rgba($cad-primary, 0.2);

$input-group-addon-bg: #fff;
$input-group-addon-font-size: $font-size-xs;
$input-group-addon-color: $cad-typo-light;

$form-check-input-checked-bg-color: $cad-primary;
$form-check-input-checked-border-color: $cad-primary;

$btn-font-weight: 500;

$btn-font-size-lg: $font-size-base;
$input-btn-font-size-lg: $font-size-base;

$btn-font-size-sm: $font-size-sm;
$input-btn-font-size-sm: $font-size-sm;

$form-label-font-size: $font-size-xs;
$form-label-font-weight: 600;
$form-label-color: $cad-typo-dark;

// Table

$table-striped-bg: $cad-inputbg;
$table-cell-padding-y: 1rem;
$table-cell-padding-x: 1rem;
$table-cell-padding-y-sm: .35rem;
$table-cell-padding-x-sm: .35rem;

$table-color: $cad-typo-default;
$table-hover-color: $table-color;
$table-hover-bg: $cad-inputbg;
$table-border-width: 0;

// Cards

$card-spacer-y: $spacer * 2;
$card-spacer-x: $spacer * 1.5;
$card-border-color: $cad-stroke;
$card-border-radius: 4px;

// Accordion

$accordion-button-active-bg: #fff;
$accordion-button-active-color: $cad-primary;

// List Group

$list-group-item-padding-y: $spacer;

$min-contrast-ratio: 2.5;

// Badges

$badge-padding-y: .5em;
$badge-padding-x: 1em;

// Navbar / Header settings

$nav-link-font-weight: 500;

// Modal

$zindex-modal: 10000;

// Tooltip

$tooltip-max-width: 300px;

// breadcrumb

$breadcrumb-divider: quote(">");
$breadcrumb-active-color: #3E3E40;
$breadcrumb-divider-color: rgba(62, 62, 64, 0.5);

@import 'bootstrap/scss/bootstrap.scss';