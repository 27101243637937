.home__marketplace {
    background-image: url('../../public/images/backgrounds/marketplace_bg_mobile.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    max-height: 100vh;
    height: 900px;
}
@media screen and (min-width: 576px) {
    .home__marketplace {
        background-image: url('../../public/images/backgrounds/marketplace_bg.jpg');
    }
}

.home__intro {
    background: linear-gradient(98.55deg, #0F2767 0%, #4093FF 77.08%), linear-gradient(98.55deg, #0F2767 0%, #122E7A 77.08%);
    min-height: 230px;
    position: relative;
    padding: 3rem 0;

    >* {
        z-index: 2;
    }

    &::after {
        background-image: url('../../public/images/backgrounds/header.svg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        content: ' ';
        position: absolute;
        inset: 0;
        z-index: 1;
    }

    .app-select__menu {
        color: $cad-typo-default !important;
    }
}

.home__seller {
    .img-fluid {
        position: relative;
        z-index: 101;
    }

    &::after {
        content: ' ';
        position: absolute;
        top: 0;
        right: 0;
        width: 50%;
        height: 100%;
        background-image: url('../../public/images/backgrounds/cogwheel.svg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        z-index: 100;
    }
}
