table {
    thead {
        tr {
            position: relative;

            &::after {
                content: ' ';
                border-bottom: 1px solid rgba(62, 62, 64, 0.05);
                width: 100%;
                position: absolute;
                display: block;
                left: 0;
                bottom: 0;
                transform: translateY(-15px);
            }
        }
    }
}

.table-responsive .visually-hidden {
    top: 0;
    left: 0;
}