.cad-control {
    @extend .form-control;

    background-color: $cad-inputbg;
}

.btn {
    &-light {
        color: $cad-primary-light;
        font-weight: normal;

        &:focus,
        &:hover {
            color: $cad-primary;
        }
    }

    &-turquoise {
        color: $cad-dark-blue;
    }
}