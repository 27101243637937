.alert-warning {
  .btn-outline-warning {
    --bs-btn-color: --bs-alert-color;
    border-color: var(--bs-alert-color);
    --bs-btn-hover-bg: --bs-alert-color;
    --bs-btn-hover-border-color: --bs-alert-color;
    --bs-btn-active-border-color: --bs-alert-color;
    &:hover {
      background: var(--bs-alert-color);
      color: #fff;
    }
  }
}