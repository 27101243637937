.datatable {
  a.text-dark,
  button.text-dark,
  a.btn.text-body {
    &:hover,
    &:focus-visible {
      color: $primary !important;
    }
  }
}
.developerlogocontainer {
  height: 100px;
  img {
    max-height: 100%;
    max-width: 75%;
    display: block;
    width: auto;
    height: auto;
  }
}
.gallery__add {
  transition: .2s ease-in-out;
  &:hover,
  &:focus-visible {
    background-color: $light;
  }
}
