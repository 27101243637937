.rs-picker {
    &-daterange {
        @extend .form-control;
        &-panel {
            font-family: 'Rubik', system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
        }
    }

    &-toggle {
        padding: 0 !important;
        border: 0 !important;
        color: #6c757d !important;
        &:focus-visible,
        &:focus {
            border-color: #1A7DFF;
            outline: 0;
            box-shadow: 0 0 0 0.21rem rgba(26, 125, 255, 0.2) !important;
        }
        &-textbox {
            padding: 0 !important;
            font-weight: 400 !important;
            color: #6c757d !important;
            &:focus,
            &:focus-visible {
                outline: 0;
                border: 0;
                box-shadow: none;
            }
        }
        &-clean {
            top: 0 !important;
            right: 0 !important;
        }
        &-caret {
            top: calc(50% - 10px) !important;
        }
        &-active {
            box-shadow: none;
        }
    }
    .rs-picker-focused {
        box-shadow: none;
    }
}