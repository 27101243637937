.accordion-item {
    &__submit {
        position: absolute;
        top: $accordion-padding-y;
        right: calc($accordion-padding-x + 30px);
        z-index: 100;

        @include media-breakpoint-up(md) {
            right: calc($accordion-padding-x + 40px);
        }
    }
}