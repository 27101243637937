.header__developer {
    position: fixed;
    z-index: 10000;
    width: 100%;
    top: 0;
    left: 0;
}

.backend-nav {
    position: fixed;
    left: 0;
    bottom: 0;
    overflow-x: auto;
    width: 100%;
    z-index: 100;
    box-shadow: 0px 100px 80px rgba(42, 37, 110, 0.06), 0px 30.1471px 24.1177px rgba(42, 37, 110, 0.0390953),
    0px 12.5216px 10.0172px rgba(42, 37, 110, 0.03), 0px 4.5288px 3.62304px rgba(42, 37, 110, 0.0209047);
    .active {
        position: relative;

        &::before {
            position: absolute;
            width: 100%;
            height: 5px;
            left: 0;
            bottom: 0;
            content: ' ';
            background: #DFF9FF;
            border-radius: 0px 4px 0px 0px;
        }

        @include media-breakpoint-up(xl) {
            &::before {
                position: absolute;
                width: 5px;
                height: 40px;
                left: -1rem; // Padding value of bootstrap
                top: 0;
                content: ' ';
                background: #DFF9FF;
                border-radius: 0px 4px 4px 0px;
            }
        }

        span {
            font-weight: 700;
        }
    }

    ul {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;

        a {
            display: flex;
            flex-direction: column;
            align-items: center;

            span {
                @include media-breakpoint-down(sm) {
                    display: none;
                }
            }
        }
    }

    @include media-breakpoint-up(xl) {
        top: var(--headerheight);
        height: 100%;
        max-width: var(--backend-nav-width);

        ul {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            a {
                display: flex;
                flex-direction: row;
                align-items: center;
            }
        }
    }
}