.imagepreviewitem {
  aspect-ratio: 16 / 10;
  overflow: hidden;
  &:nth-child(2),
  &:nth-child(3) {
    display: none;
  }
  &__zoombutton {
    background-color: rgba(255,255,255,.8);
    transition: .2s ease-in-out;
    cursor: pointer;
    &:hover,
    &:focus {
      background-color: #fff !important;
      color: $primary !important;
    }
  }
}
@include media-breakpoint-up(md){
  .imagepreviewitem {
    &:nth-child(2),
    &:nth-child(3) {
      display: block;
    }
  }
}