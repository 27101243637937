.categories {
    $colors: #F5F5FA,
    #8CBEFF,
    #4093FF,
    #465F80,
    #3375CC,
    #465F81,
    #7098CC,
    #D9E9FF;

    @each $color in $colors {
        $i: index($colors, $color);

        .category-#{$i} {
            background: #{$color};
        }
    }
}