.login__appendix {
    position: relative;

    &::before {
        content: '';
        height: 6px;
        width: 70%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 0px 6px 0px 0px;
        background: $cad-turquoise;
    }

    &::after {
        content: '';
        height: 6px;
        width: 30%;
        position: absolute;
        bottom: 0;
        right: 0;
        border-radius: 0px 0px 0px 6px;
        background: $cad-primary;
    }
}