// fonts

/* rubik-regular - latin */
@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    src: local(''),
        url('/fonts/rubik/rubik-v21-latin-regular.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('/fonts/rubik/rubik-v21-latin-regular.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* rubik-500 - latin */
@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    src: local(''),
        url('/fonts/rubik/rubik-v21-latin-500.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('/fonts/rubik/rubik-v21-latin-500.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* rubik-700 - latin */
@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    src: local(''),
        url('/fonts/rubik/rubik-v21-latin-700.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('/fonts/rubik/rubik-v21-latin-700.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

// General
body,
html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: normal;
    font-size: 15px;
    font-family: 'Rubik', system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

hr {
    color: $cad-stroke;
}
a {
    transition: .2s ease-in-out;
    &.arrowlink {
        svg {
            transition: .15s ease-in-out;
        }
        &:hover,
        &:focus-visible {
            svg {
                transform: translateX(50%);
            }
        }
    }
}
// Tables

th {
    color: $cad-typo-dark;
    font-size: .8rem;
}

// Form

.form-check-input {
    margin-top: 0.15em;
}
// Button

.btn {
    text-decoration: none;
    &:hover,
    &:focus-visible {
        text-decoration: none;
    }
}
// Utility

.txt-display {
    font-family: var(--displayfont);
}

.text-default {
    color: var(--bs-body-color);
}
.text-darkblue {
    color: $cad-dark-blue !important;
}
.fs-default {
    font-size: 1rem;
}

.cursor-pointer {
    cursor: pointer;
}

.text-rainbow {
    background: $cad-rainbow;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.form-select {
    overflow: hidden;
}