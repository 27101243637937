// This is a plugin for range filter ( 20$ - 50$ for instance)
// Update the styles of this plugin

.rc-slider-rail {
    background-color: rgba(64, 147, 255, 0.15) !important;
}

.rc-slider-track {
    background-color: $cad-primary !important;
}

.rc-slider-handle {
    border-color: $cad-primary  !important;
}