/* ==========================================================================
   Helper classes
   ========================================================================== */

.ir {
    background-color: transparent;
    border: 0;
    overflow: hidden;
    *text-indent: -9999px;
}

.ir:before {
    content: "";
    display: block;
    width: 0;
    height: 150%;
}

.hidden {
    display: none !important;
    visibility: hidden;
}

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

.invisible {
    visibility: hidden;
}

.clearfix:before,
.clearfix:after {
    content: " ";
    display: table;
}

.clearfix:after {
    clear: both;
}

.clearfix {
    *zoom: 1;
}

.jumplink {
    position: absolute;
    left: -9999px;
    display: block;
    z-index: 1000;
}

.jumplink:focus {
    left: 50%;
    margin-left: -125px;
    width: 250px;
    background: black;
    color: white;
    text-decoration: none;
    padding: 10px;
}

$sizes: 40px, 50px, 60px, 70px, 75px, 80px, 90px, 100px, 110px, 120px, 150px, 200px, 240px;

@each $size in $sizes {
    .mw-#{$size} {
        max-width: $size;
    }
}

.w-40px {
    width: 40px;
}

.h-40px {
    height: 40px;
}

.fit-cover {
    object-fit: cover;
}

.flex-basis {
    flex-basis: 50%;
}

@include media-breakpoint-up(md) {
    .flex-basis {
        flex-basis: 40%;
    }
}

@include media-breakpoint-up(lg) {
    .flex-basis {
        flex-basis: 25%;
    }
}

.cad-container {
    @extend .container;
    max-width: 100%;

    @include media-breakpoint-up (lg) {
        max-width: calc(100% - 100px);
    }
}

.backend-container {
    min-height: 100vh;
    top: var(--headerheight);
    position: relative;
    left: 0;

    @include media-breakpoint-up (xl) {
        left: var(--backend-nav-width);
        width: calc(100% - var(--backend-nav-width));
    }
}

.divider {
    flex-grow: 1;
    border-bottom: 1px solid rgba(62, 62, 64, 0.05);
}

.tabs {
    .active {
        position: relative;

        &::before {
            position: absolute;
            width: 100%;
            height: 5px;
            left: 0;
            bottom: 0;
            content: ' ';
            background: $cad-primary;
            border-radius: 0px 4px 4px 0px;
        }
    }
}

progress.progress--danger::-moz-progress-bar {
    background: $cad-error;
}

progress.progress--danger::-webkit-progress-value {
    background: $cad-error;
}

progress.progress--danger {
    color: $cad-error;
}

progress.progress--success::-moz-progress-bar {
    background: $cad-success;
}

progress.progress--success::-webkit-progress-value {
    background: $cad-success;
}

progress.progress--success {
    color: $cad-success;
}


$breakpoints: map-keys($grid-breakpoints);

@mixin border-breakpoints () {
    .border {
        @each $breakpoint in $breakpoints {
            @include media-breakpoint-up ($breakpoint) {
                &-#{$breakpoint} {
                    &-end {
                        border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
                    }

                    &-start {
                        border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
                    }

                    &-top {
                        border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
                    }

                    &-bottom {
                        border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
                    }
                }
            }
        }
    }
}

@include border-breakpoints();

.m {
    &t {
        &-6 {
            margin-top: $spacer * 4;
        }

        &-7 {
            margin-top: $spacer * 5;
        }
    }
}

.btn {
    &-xl {
        --bs-btn-padding-y: 1.5rem;
        --bs-btn-padding-x: 2rem;
        --bs-btn-font-size: 1.2rem;
        --bs-btn-border-radius: 0.5rem;
    }

    &-xxl {
        --bs-btn-padding-y: 2rem;
        --bs-btn-padding-x: 2.5rem;
        --bs-btn-font-size: 1.5rem;
        --bs-btn-border-radius: 0.5rem;
    }
}

.fs-7 {
    font-size: 0.6rem;
}
