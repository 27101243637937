// Breakpoints

// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1280px;

// Large tablets and desktops
$screen-xxl-min: 1680px;

// Fonts

:root {
    --copyfont: 'Rubik', sans-serif;
    --displayfont: 'Rubik', sans-serif;
    --headerheight: 70px;
    --backend-nav-width: 300px;
}
@media screen and (min-width: 992px) {
    :root {
        --headerheight: 90px;
    }
}

// Colors (Must be declared as SCSS Variables for usage in Bootstrap-Customization)

$cad-primary: #1A7DFF;
$cad-rainbow: linear-gradient(89.07deg, #8CBEFF 0.8%, #1A7DFF 99.51%);
$cad-light-primary: #8CBEFF;
$cad-turquoise: #9FEAFF;
$cad-primary-light: #4093FF;
$cad-midnight: #303740;
$cad-dark-blue: #112B72;
$cad-light-grey: #D9D9D8;
$cad-muted: #F9F9FC;

$cad-success: #279E24;
$cad-error: #FF6969;
$cad-warning: #FEDD76;

$cad-inputbg: #F5F9FC;
$cad-stroke: #F2F0F0;
$cad-lightgray: #EEF5FD;
$cad-mediumgrey: #D9D9D9;
$cad-gray: #595758;

$cad-typo-dark: #252526;
$cad-typo-default: #595758;
$cad-typo-light: #6B7177;
$cad-typo-lighter: #BFBABA;
