.badge.text-bg-primary {
    color: $cad-primary-light !important;
    background-color: rgba($cad-primary-light, 0.1) !important;
}

.badge.text-bg-success {
    color: $cad-success !important;
    background-color: rgba($cad-success, 0.1) !important;
}

.badge.text-bg-danger {
    color: $cad-error !important;
    background-color: rgba($cad-error, 0.1) !important;
}

.badge.text-bg-warning {
    color: $cad-warning !important;
    background-color: rgba($cad-warning, 0.1) !important;
}

.badge-sm {
    padding: 3px 8px 4px 8px;
    font-size: 11px;
}
