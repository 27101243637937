.plugincard {
  > a {
    position: relative;
    &:after {
      left: 0;
      bottom: 0;
      background: $primary;
      height: 6px;
      width: 100%;
      content: " ";
      display: block;
      position: absolute;
      transform-origin: left bottom;
      transform: scaleX(0);
      transition: .125s ease-in-out;
    }
    &:hover,
    &:focus-visible {
      box-shadow: 0px 4px 8px rgba(18, 46, 122, 0.15);
      color: $body-color;
      &:after {
        transform: scaleX(1);
      }
    }
  }
  img {
    max-height: 150px;
    max-width: 75%;
  }
  &--featured {
    > a {
      border-color: #DFE6ED !important;
      &:after {
        background: $cad-dark-blue;
      }
      > .bg-light {
        border-bottom: 6px solid $primary;
      }
    }
  }
}