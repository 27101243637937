.trust-elements {
  position: relative;

  &::before {
    content: '';
    height: 15px;
    width: 20%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0px 15px 0px 0px;
    background: $cad-turquoise;
  }

  &::after {
    content: '';
    height: 12px;
    width: 40%;
    position: absolute;
    background: $cad-dark-blue;
    bottom: 0;
    right: 0;
    border-radius: 0px 0px 0px 12px;
  }
}
