.cms {
    // So the user in Stripe can decide whether its a normal col-12 with outer padding
    // or a "full-width" without the containers padding.
    // To accomplish this, we use the class "cms__full-width" on the container .row.
    // Because bootstrap sets margins and stuff, we need to remove them manually and use vw.
    &--full-width {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        width: 100vw;
        margin-right: 0;
        margin-left: 0;

        .row {
            margin-right: 0;
            margin-left: 0;
        }

        .col-12:first-child {
            padding-right: 0;
            padding-left: 0;
        }
    }

    &__component {
        &-banner {
            display: grid;
            align-items: center;
            justify-items: center;
            > * {
                grid-column: 1 / 2;
                grid-row: 1 / 2;
            }
        }
    }

    > .container > .row:first-child {
        &.mt-5 {
            margin-top: 0 !important;
            padding-top: 3rem !important;
        }
        &.mt-4 {
            margin-top: 0 !important;
            padding-top: 1.5rem !important;
        }
        &.mt-3 {
            margin-top: 0 !important;
            padding-top: 1rem !important;
        }
        &.mt-2 {
            margin-top: 0 !important;
            padding-top: 0.5rem !important;
        }
        &.mt-1 {
            margin-top: 0 !important;
            padding-top: 0.25rem !important;
        }
    }
}