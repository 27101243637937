.categorypanel {
  min-height: 80px;
  transition: all .25s ease-in-out;
  position: relative;
  overflow: hidden;
  &:after {
    left: 0;
    bottom: 0;
    background: $primary;
    height: 4px;
    width: 100%;
    content: " ";
    display: block;
    position: absolute;
    transform-origin: left bottom;
    transform: scaleX(0);
    transition: .125s ease-in-out;
  }
  &:hover,
  &:focus-visible {
    box-shadow: 0px 4px 8px rgba(18, 46, 122, 0.15);
    color: $primary !important;
    &:after {
      transform: scaleX(1);
    }
  }
}