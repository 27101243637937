.ReactTags {
    &__tags {
        display: flex;
        flex-direction: column;
    }

    &__selected {
        order: 2;
        margin-top: 10px;
        display: flex;

        span {
            @extend .bg-light;
            @extend .rounded;
            @extend .p-2;
            @extend .fs-6;

            margin-right: 10px;
            display: flex;
            align-items: center;
        }

        button {
            @extend .btn;
            padding: 0;
            margin-left: 5px;
        }
    }

    &__tagInput {
        input {
            @extend .form-control;
        }
    }
}