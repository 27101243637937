header {
    .navbar {
        height: var(--headerheight);

        &-collapse {
            position: fixed;
            left: 0;
            top: var(--headerheight);
            background: white;
            .modal-open & {
                background: transparent;
            }
        }
    }

    @include media-breakpoint-down(lg) {
        .navbar {
            &-collapse {
                padding-bottom: 40px;
                margin: 0;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .navbar {
            &-collapse {
                position: inherit;
            }
        }
        > .navbar > div > .navbar-collapse > div > ul > li > a,
        > .navbar > div > .navbar-collapse > div > ul > li > span {
            position: relative;
            &:after {
                position: absolute;
                width: 100%;
                left: 0;
                bottom: 0;
                height: 3px;
                background: $primary;
                transition: .2s ease-in-out;
                content: " ";
                display: block;
                transform-origin: left center;
                border: 0;
                margin-left: 0;
                transform: scaleX(0);
            }
        }
        .dropdown-fullwidth.show {
            display: flex;
            position: fixed !important;
            left: 0;
            top: 90px;
            width: 100vw;
            box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.15);
        }
    }
}

.header--fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
}
header[class*="bg-"] {
    + main {
        margin-top: var(--headerheight);
        .backend-container {
            margin-top: calc(var(--headerheight) * -1)
        }
    }
}

.header--shadow {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    background: white;
}