.skybarad {
  display: none;

  @media (min-width: 1480px) {
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translateX(670px);
    display: block;
  }
}
