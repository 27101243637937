.pagefooter__bgimage {
    background-image: url('../../public/images/backgrounds/footer.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    bottom: 0;
    left: 0;

    &::after {
        content: '';
        background: $cad-primary-light;
        height: 4px;
        width: 40%;
        position: absolute;
        bottom: 0;;
        border-radius: 0px 4px 0px 0px;
    }
}

.pagefooter_separator {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}