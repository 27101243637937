.switch-field {
  position: relative;
  display: inline-flex;
  align-items: center;
  margin: 0 auto;
  padding: 4px;
  background: #fff;
  border-radius: 100vh;
  gap: var(--space-smaller);
  width: auto;
  border: $cad-light-grey 1px solid;
  input {
    position: absolute !important;
    clip: rect(0,0,0,0);
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden;
  }
  input:checked + label {
    transition: 0.5s;
    background-color: $primary;
    color: #ffffff;
  }
  label {
    display: inline-block;
    border-radius: 100vh;
    text-align: center;
    padding: 0.5rem 1.5rem;
    transition: .25s ease-in-out;
    text-transform: initial;
    color: var(--fcrm-text);
    letter-spacing: 0;
    background-color: transparent;
    cursor: pointer;
  }
}

